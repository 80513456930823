var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4 pb-4",attrs:{"id":"reset"}},[_vm._m(0),_c('div',{staticClass:"form-box form-box--sign-in aos-init aos-animate",attrs:{"data-aos":"fade-up","data-aos-duration":"500","data-aos-delay":"500","data-aos-once":"true"}},[_c('validation-observer',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('Alert',{ref:"alert"}),_c('form',{ref:"form",staticClass:"custom-form aos-init aos-animate needs-validation",attrs:{"method":"POST","autocomplete":"off","data-aos":"fade-up","data-aos-duration":"500","data-aos-delay":"300","data-aos-once":"true"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex justify-content-between mg-b-5"},[_c('label',{staticClass:"mg-b-0-f"},[_vm._v("New Password")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password),expression:"model.password"}],staticClass:"form-control",class:classes,attrs:{"type":"password","name":"password","placeholder":"Enter your password","autocomplete":"new-password"},domProps:{"value":(_vm.model.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"confirm password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex justify-content-between mg-b-5"},[_c('label',{staticClass:"mg-b-0-f"},[_vm._v("Confirm Password")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.confirmPassword),expression:"model.confirmPassword"}],staticClass:"form-control",class:classes,attrs:{"type":"password","name":"confirmPassword","placeholder":"Confirm your password","autocomplete":"off"},domProps:{"value":(_vm.model.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "confirmPassword", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('stateful-button',{staticClass:"btn btn-respect-mart rounded-55 text-white",attrs:{"type":"submit","busy":_vm.busy}},[_vm._v("Submit")])],1)]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-title section-title--l5 pb-4"},[_c('h2',{staticClass:"section-title__heading aos-init aos-animate",attrs:{"data-aos":"fade-up","data-aos-duration":"500","data-aos-once":"true"}},[_vm._v(" Complete Password Recovery ")])])}]

export { render, staticRenderFns }