























































































import { Component } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";

@Component({})
export default class ResetPasswordForm extends BaseForm {
  public labels: Record<string, string> = {
    password: "New Password",
    confirmPassword: "Confirm Password",
  };

  public model: Record<string, any> = {
    password: null,
    confirmPassword: null,
  };

  public async send() {
    this.$emit("done", {});
  }
}
